// import Routes from "config/routes";
import React from "react";
// import "./globalStyles.css";
import SPApp from "./pages/SPApp/SPApp.jsx"

function App () {
  return (
    <>
      <SPApp />
    </>
  );
};

export default App;